import { ITranslationKeys } from "./keys";

// istanbul ignore next
export const esTranslations: ITranslationKeys = {
  translation: {
    "AND": 'y',
    "BATTERY": "baterías",
    "BATTERY_other": "baterías",
    "BATTERY_WITH_COUNT": "{{count}} batería",
    "BATTERY_WITH_COUNT_other": "{{count}} baterías",
    "DEVICE": "Dispositivo",
    "DEVICE_other": "Dispositivos",
    "DEVICES_LIST_TITLE": "Lista de dispositivos",
    "GROUP": "Grupo",
    "GROUP_other": "Grupos",
    "GROUP_WITH_COUNT": "{{count}} grupo",
    "GROUP_WITH_COUNT_other": "{{count}} grupos",
    "NO_GROUP": "Ningún grupo",
    NO_GROUP_title: '$t(translation:NO_GROUP)',
    NO_HOME_LOCATION: 'Sin ubicación de inicio',
    "BATTERIES_IN_GROUP": "$t(BATTERY_WITH_COUNT, {'count': {{baterías}} }) dans $t(GROUP_WITH_COUNT, {'count': {{grupos}} })",
    "LICENSED": "Licencias",
    "MAPS": "Mapas",
    "PURCHASE": "Compra",
    "PRODUCT_SELECTION": "Selección de productos",
    "ADMIN": "Administrador",
    "OF": "de",
    "DOWNLOAD": "Descargar",
    "ALL": "Todo",
    "NO_PROBLEMS": "Sin Problemas",
    "HEALTH_WARNING": "Advertencia de Estado",
    "POOR_HEALTH": "Mala Salud",
    "CYCLE_COUNT_WARNING": "Advertencia sobre Ciclos de Carga",
    "HIGH_CYCLE_COUNT": "Ciclos de Carga Altas",
    "LAST_TIME_SEEN_STATEMENT": "{{timePeriod}}",
    "VIEW_IN_CORE": "Mirar en Core",
    "VIEW_IN_SMART_SERVICE": "Mirar en Smart Service",
    "FILTERS": "Filtros",
    "APPLY": "Applicar",
    "CANCEL": "Cancelar",
    "CONFIRM": "Confirmar",
    "DELETE": "Borrar",
    "NEW": "Nueva",
    "FILTER": "Filtrar",
    "EDIT": "Editar",
    "EXPORT": "Exportar",
    "SEARCH": "Buscar",
    "TO": "a",
    "INFORMATION": "Información",
    "CHARGE_LEVEL": "Nivel de Carga",
    "FILTER_STATUS_TEXT": "{{filterName, lowercase}} encontrado con el siguiente filtro:",
    "FILTER_STATUS_TEXT_other": "{{filterName, lowercase}} encontrado con los siguientes filtros:",
    "ACROSS": "a través de",
    "ALL_CATEGORIES": "todas las categorias",
    "IN_NEED_OF": "en necesidad de",
    "REPLACEMENT": "reemplazo",
    "WITH": "con",
    "WARNINGS": "Advertencias",
    "ENROLLMENT": "Inscripción",
    "SETTINGS": "Ajustes",
    "ABOUT": "Acerca",
    "HELP_CENTER": "Centro de Ayuda",
    "SIGN_OUT": "Desconectar",
    "TO_LOWERCASE": "{{text, lowercase}}",
    "NOUN_AGREEMENT_HOURS": "Último recogido{{valor numérico}} horas",
    "NOUN_AGREEMENT_DAYS": "Último recogido{{valor numérico}} días",
    "EDIT_FILTERS": "Editar Filtros",
    "CLEAR_ALL": "Borrar Todos",
    "LOADING": "Cargando",
    "MORE": "más",
    "MB": "MB",
    "GB": "GB",
    "BATTERY_FIRST_CAPITAL": "Batería",
    "BATTERY_FIRST_CAPITAL_other": "Baterías",
    "IMEI": "IMEI",
    "CPU": "CPU",
    "IDLE": "Inactivo",
    "PROMPT_MESSAGE": "Salir de esta página? Es posible que no se guarden sus cambios.",

    // CHARTS
    "NO_DATA_AVAILABLE": "No hay datos disponibles",
    "RADIO_OFF": "Radio apagada",
    "MOBILE_AND_WIFI": "Móvil y Wi-Fi",
    "MOBILE": "Móvil",
    "WIFI": "Wi-Fi",
    "DATA": "Datos",

    // PRODUCT SUBTITLES
    "ELEMEZ_BATTERY_SUBTITLE": "Essentials - Realice un seguimiento, identifique y remplace las baterías antes de que impacten en su negocio.",
    "PANASONIC_BATTERY_SUBTITLE": "Monitor -  Identifique y remplace las baterías antes de que impacten en su negocio.",
    "ELEMEZ_CORE_V2_SUBTITLE": "Core - Visualice y actúe sobre métricas, eventos y tendencias clave de rendimiento en todo el estado.",
    "PANASONIC_CORE_V2_SUBTITLE": "Smart Service - Servicio inteligente: visualice y actúe sobre las métricas, eventos y tendencias clave de rendimiento en todo el sector.",

    // CORE SELECTOR BAR
    "OVERVIEW": "Visión general",
    "DEVICES": "Dispositivos",
    "APPLICATIONS": "Aplicaciones",
    "ANOMALIES": "Anomalías",
    "PERFORMANCE": "Rendimiento",

    DEVICE_NOT_FOUND: "No se encontró ningún dispositivo que coincidiera con la información proporcionada",
    MULTIPLE_DEVICES_FOUND: 'Se encontró más de un dispositivo que coincidió con la información proporcionada',

    //CORE DEVICE OVERVIEW TAB
    "ALERTS": " Alertas ({{count}})",
    "CONNECTIONS_AND_ASSETS": "Conexiones y componentes",
    "LAST_CONTACT": "último contacto",
    "LAST_KNOWN_WIFI": "Última conexión Wi-Fi conocida",
    "NAME_SSID": "Nombre (SSID)",
    "ADDRESS_BSSID": "Dirección (BSSID)",
    "UPDATED": "Actualizado",
    "STATUS": "Estado",
    "STATUS_other": "Estado",
    "SERIAL": "Número de serie",
    "SERIAL_list": "Numéro de serie de la batería {{ batteryNumber }}",
    "SIM_SERIAL": "Número SIM",
    "SIM_SERIAL_list": "Número SIM {{ simNumber }}",
    "OPERATOR": "Operador de la tarjeta SIM",
    "OPERATOR_list": "Operador de la tarjeta SIM {{ simNumber }}",
    "OPERATOR_CODE": "Código del operador",
    "SYSTEM": "Información del sistema",
    "OPERATING_SYSTEM": "Sistema operativo",
    "BUILD_VERSION": "Versión de Compilación",
    "FIRMWARE_VERSION": "Versión de Firmware",
    "TIMEZONE": "Zona horaria",
    "TIME": "Tiempo",
    "GROUPS": "Grupos",
    "REPLACE": "Remplace",
    "WARNING": "Advertencia",
    "GOOD": "Bien",
    "ELEMEZ_VERSION": "Versión de Elemez",
    "EXTENSION_VERSION": `Versión de l'ampliación de Elemez`,
    "ENROLLMENT_DATE": "Fecha de inscripción",
    "STORAGE": "Almacenamiento",
    "AVAILABLE": "Disponible",
    "TOTAL_SPACE": "Espacio Total",
    "USED": "Usado",
    "TOTAL_RAM": "Total RAM",
    "COMPUTER_NAME": "Nombre de la Computadora",
    "NO_SMART_BATTERY_DETECTED": "Ninguna batería inteligente detectada",
    "NO_SIM_DETECTED": "Ninguna tarjeta SIM detectada",
    "SIM": "SIM",

    //OVERVIEW PAGE
    "PROBLEM": "Problema",
    "WELCOME_TITLE": "Bienvenido a su Elemez",
    "ESTATE_ALERT_COUNT": "Problemas detectados en su flota de dispositivos. <1>{{totalWithAlerts, localiseNumber}}</1> de su <3>{{total, localiseNumber}}</3>dispositivos está<5>actualmente en estado de alerta.</5><6>Ver dispositivos</6>",
    "ESTATE_ALERT_COUNT_other": "Problemas detectados en su flota de dispositivos. <1>{{totalWithAlerts, localiseNumber}}</1> de sus <3>{{total, localiseNumber}}</3> dispositivos están<5>actualmente en estado de alerta.</5><6>Ver dispositivos</6>",
    "ESTATE_ALERT_COUNT_SIMPLE": "<0>{{totalWithAlerts, localiseNumber}}</0> de tus <2>{{total, localiseNumber}}</2> dispositivos está<4>alertando.</4>",
    "ESTATE_ALERT_COUNT_SIMPLE_other": "<0>{{totalWithAlerts, localiseNumber}}</0> de sus <2>{{total, localiseNumber}}</2> dispositivos están<4>alertando.</4>",
    "NO_ISSUES_DETECTED": "No se han detectado problemas en los dispositivos de su flota.",
    "VIEW_DEVICES": "Mostrar Todos Dispositivos",

    //DEVICES LIST
    "FILTER_MODAL_HEADER": "Filtros de dispositivo",
    "FILTER_MODAL_SUBHEADER": "Filtrar su lista de dispositivos por las categorías y características a continuación",
    FILTER_MODAL_TAB_1: `Detalles del dispositivo`,
    FILTER_MODAL_TAB_2: `Tipos de alerta`,
    "FILTER_COUNT_MESSAGE": "Dispositivos que coinciden con sus filtros",
    "FILTER_NONE_SELECTED_MESSAGE": "Ningún filtro seleccionado",
    "FILTER_APPLY": "Aplicar filtros",
    "MANUFACTURER": "Fabricante",
    "MANUFACTURER_other": "Fabricantes",
    "MODEL": "Modelo",
    "MODEL_other": "Modelos",
    "SERIAL_NUMBER": "Número de serie",
    "ASSET_TAG": "Número de inventario",
    "HOME_LOCATION": "Ubicación de inicio",
    "HOME_LOCATION_other": "Ubicaciones de inicio",
    "LAST_SEEN": "Último contacto",
    "MOBILE_DATA": "Datos móviles",
    "MOBILE_DATA_TOOLTIP_TEXT": "$t(MOBILE_DATA) registrados durante los últimos 30 días.",
    "WIFI_DATA": "Datos Wi-Fi",
    "WIFI_DATA_TOOLTIP_TEXT": "$t( WIFI_DATA)  registrados durante los últimos 30 días.",
    "TOTAL_DATA": "Datos totales",
    "MATCHING_SEARCH": "coincida con su búsqueda",
    "SEARCH_MATCH_STATEMENT": "resultado que coincide con su búsqueda",
    "SEARCH_MATCH_STATEMENT_other": "resultados que coinciden con su búsqueda",
    "NAME": "Nombre",
    "NAME_REQUIRED": "Nombre  - <1>Requerido</1>",
    "JOB_TITLE_REQUIRED": "Título de Trabajo  - <1>Requerido</1>",
    "VERSION": "Versión",
    "SIZE": "Talla",
    "SIZE_TOOLTIP_TEXT": "Talla de aplicaciones para dispositivos utilizar Android 8 o superior.",
    "LAST_UPDATED": "Ultima actualización",
    "LAST_USED": "Ultimo uso",
    "AVERAGE_DAILY_USAGE": "Utilización media diaria",
    "AVERAGE_DAILY_CPU_USAGE": "Utilización media diaria de CPU",
    "USER_EXPERIENCE": "Experiencia del usuario",
    "LOCATION": "Ubicación",
    "LOCATION_UPDATED": "Ubicación actualizada",
    "LAST_LOCATION_LOCK": "Bloqueo de última ubicación",
    "LOCATION_UPDATED_TOOLTIP": "Esta es la hora en que el agente registró por última vez la corrección de ubicación del dispositivo.",
    "LAST_LOCATION_LOCK_TOOLTIP": "Este es el momento en que el dispositivo adquirió la última ubicación fija.",
    "UTILISATION": "Utilización",
    "BATTERY_ESTATE_OVERVIEW_TITLE": "Visión general de las baterías de su flota",
    "DEVICE_ESTATE_OVERVIEW_TITLE": "Visión general de los dispositivos de su flota",
    "ESTATE_OVERVIEW_SUBTITLE": "{{count, localiseNumber}} {{label, lowercase}} total",
    "ESTATE_OVERVIEW_TABLE_TITLE": "Visión general de los activos",
    "ESTATE_OVERVIEW_TABLE_SUBTITLE": `Desglose del estado de la alerta de sus ubicaciones de inicio y grupos.`,
    "CORE_ESTATE_OVERVIEW_TABLE_SUBTITLE": "Desglose de alertas inmobiliarias",
    "NO": "no",
    "ALERTS_TEXT": "alertas",
    "NO_SELECTION_TEXT": "Ningún {{item, lowercase}} seleccionado",
    "DEVICE_STATUS_HEADER": "Estado del dispositivo",
    "DAYS_TEXT": "Día",
    "DAYS_TEXT_other": "Días",
    "GROUPS_FILTER_HEADER": "Filtros de grupos",
    "HOMELOCATIONS_FILTER_HEADER": "Filtro de ubicaciones de inicio",
    "IN_PROGRESS": "En curso",

    "CLOSE": "Cerrar",

    "MOBILE_DATA_USED": "Datos móviles utilizados",
    "WIFI_DATA_USED": "Datos Wi-Fi utilizados",

    "AVERAGE_DISCHARGE": "Promedio de descarga",
    "DEVICE_NAME": "Nombre del dispositivo",
    "OS_VERSION": "Versión del OS",
    "BIOS_VERSION": "Versión del cliente BIOS",
    "CLIENT_VERSION": "Versión del cliente",
    "EXTENSION_VERSION_DEVICE": "Versión de extensión",
    "STORAGE_USED": "Almacenamiento utilizado",

    // Pagination bar
    JUMP_TO_PAGE: 'Salta a la página',
    PER_PAGE: 'Por página',
    GO: 'Ir',
    RESULT: "resultado",
    RESULT_other: "resultados",

    CHECKBOX_MESSAGE: "Sí, entiendo.",

    // Processes Tab
    "PROCESSES": "Procesos",

    // What's New
    WHATS_NEW: "Qué hay de nuevo",
  },
  anomaly: {
    // Anomalies page
    ANOMALIES: "$t(translation:ANOMALIES)",
    RESULT: "resultado",
    RESULT_other: "resultados",
    DESCRIPTION: "Descripción",
    LAST_AFFECTED: "Últimos afectados",
    LAST_AFFECTED_TOOLTIP: "El número de dispositivos que tenían esta característica y este tipo de alerta en el momento de la última ejecución del algoritmo.",
    LAST_AFFECTED_TOOLTIP_LABEL: "Más sobre los últimos afectados",
    TOTAL_AFFECTED: "Total afectado",
    TOTAL_AFFECTED_TOOLTIP: "El número único de dispositivos que tenían esta característica y este tipo de alerta durante el período de tiempo para esta anomalía.",
    TOTAL_AFFECTED_TOOLTIP_LABEL: "Mas sobre total afectado",
    HIGHEST_AFFECTED: "Número de afectados más elevado",
    HIGHEST_AFFECTED_TOOLTIP: "El número más elevado de dispositivos que a la vez han tenido esta característica y este tipo de alerta en el período de tiempo para esta anomalía.",
    HIGHEST_AFFECTED_TOOLTIP_LABEL: "Más sobre número de afectados más elevado",
    OCCURRENCES: "Ocurrencia",
    OCCURRENCES_TOOLTIP: "Cuántas veces el número de dispositivos con esta característica y este tipo de alerta desencadenó una alerta de anomalía durante el período de tiempo.",
    OCCURRENCES_TOOLTIP_LABEL: "Más sobre ocurrencia",
    FIRST_DETECTED: "Primera detección",
    FIRST_DETECTED_TOOLTIP: "La primera fecha en que se desencadenó una alerta de anomalía para esta combinación de característica y tipo de alerta.",
    FIRST_DETECTED_TOOLTIP_LABEL: "Mas sobre primera detección",
    LAST_DETECTED: "Última detección",
    LAST_DETECTED_TOOLTIP: "La última fecha en que se desencadenó una alerta de anomalía para esta combinación de característica y tipo de alerta.",
    LAST_DETECTED_TOOLTIP_LABEL: "Mas sobre última detección",
    PROPERTY: "Propiedad",
    PROPERTY_other: "Propiedades",
    THIS_PROPERTY: "esta propiedad",
    THIS_PROPERTY_other: "estas propiedades",
    ALERT_TYPE: 'Tipo de alerta',
    LESS_THAN_ONE_PERCENT: "Menos que 1%",
    ACTIVE: "activos",
    ALL: "Todos los",
    ONE_IN_EVERY_STATEMENT: "1 de cada {{ratio}}",
    HAS: "tiene",
    HAS_other: "tienen",
    DEVICES_WITH_ALERT_TYPE_STATEMENT: "dispositivos {{active}} {{has}} este tipo de alerta",
    DEVICES_WITH_PROPERTY_AND_ALERT_TYPE_STATEMENT: "dispositivos {{active}} con esta propiedad {{has}} este tipo de alerta.",
    DEVICES_WITH_PROPERTY_AND_ALERT_TYPE_STATEMENT_other: "dispositivos {{active}} con estas propiedades {{has}} este tipo de alerta.",
    MORE_THAN_EXPECTED_STATEMENT: "Los dispositivos con esta propiedad tienen aproximadamente {{multiplier}} veces más alertas de este tipo de lo esperado.",
    MORE_THAN_EXPECTED_STATEMENT_other: "Los dispositivos con estas propiedades tienen aproximadamente {{multiplier}} veces más alertas de este tipo de lo esperado.",
    ANOMALY_FILTER_MODAL_HEADER: "Filtro de anomalías",
    ANOMALY_FILTER_MODAL_SUBHEADER: "Filtre su lista de anomalías por tipo de alerta a continuación",
    ANOMALY_DETECTION_NOT_SUPPORTED: "Actualmente hay menos de 50 dispositivos en este mundo. Para operar, esta función requiere un mínimo de 50 dispositivos en el mundo.",
    ANOMALY_DETECTION_NOT_SUPPORTED_disabled: "La Detección de Anomalías es deshabilitada. Para operar, esta función requiere un mínimo de 50 dispositivos en el mundo y se lo puede habilitar mediante Ajustes.",

    ANOMALY_DETECTION_SUPPORT_WARNING: "Conviene observar que esta función requiere un mínimo de 50 dispositivos en el mundo.",

    // Anomaly details
    ANOMALY_HISTORY: "Historial de anomalías",
    ANOMALY_DEVICE_CHART_TITLE: "Dispositivos afectados en los últimos 90 días",
    HISTORY: "Historia",
    DEVICES_AFFECTED_ANOMALY_RAISED: "Dispositivos afectados (anomalía levantada)",
    DEVICES_AFFECTED_ANOMALY_NOT_RAISED: "Dispositivos afectados (anomalía no planteada)",

    // Anomaly Devices
    ANOMALY_DEVICES_TABLE_TITLE: "Lista de dispositivos afectados por esta anomalía en {{date}}",
    ANOMALY_DEVICES_TITLE: "Lista de dispositivos afectados",
    ANOMALY_DEVICES_DOWNLOAD_FILE_NAME: "anomalía({{description}})_dispositivos",
    ANOMALY_DEVICES_LIST_TITLE: "$t(translation:DEVICES_LIST_TITLE)",

    AVERAGE_DISCHARGE_STATUS: `Promedio elevado de descarga por hora`,
    DEVICE_LOCATION_ACCESS_POINT_STATUS: `El punto de acceso no está en la ubicación de origen`,
    DEVICE_LOCATION_DISTANCE_STATUS: `Distancia lejos de la ubicación de casa`,
    DEVICE_LOCATION_TIME_STATUS: `Tiempo fuerade la ubicación de casa`,
    DEVICE_USER_EXPERIENCE_REBOOT_STATUS: `Reinicio`,
    DEVICE_TIME_ERROR_STATUS: `Error de tiempo`,
    DEVICE_USER_EXPERIENCE_DROP_STATUS: `Caídas`,
    DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS: `Bajo consumo`,
    DEVICE_UTILISATION_IDLE_STATUS: `Utilización inactiva`,
    DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS: `Fuera de contacto`,
    DEFAULT_ALERT: `Alerta de dispositivos`,
  },
  redirect: {
    REDIRECT_TO_DEVICES: 'Volver a la lista de dispositivos',
    REDIRECT_TO_APPLICATIONS: `Volver a la lista de aplicaciones`,
    REDIRECT_TO_ZONES: "Ver lista de todas las zonas",
    APPLICATION_NOT_FOUND: 'No se encontró ninguna aplicación que coincidiera con la información proporcionada',
    REDIRECT_TO_ANOMALIES: `Volver a la lista de anomalías`,
    ANOMALY_NOT_FOUND: 'No se encontró ninguna anomalía que coincidiera con la información proporcionada',
    REDIRECT_TO_PROCESSES: 'Ver lista de todos los procesos',
    PROCESS_NOT_FOUND: 'No se encontró ningún proceso que coincida con la información proporcionada'
  },
  accessDenied: {
    THIS_PAGE: 'esta página',
    THIS_PRODUCT: 'este producto',
    THIS_WORLD: 'este mundo',
    ACCESS_DENIED_PREFIX: 'No tiene permiso para acceder a',
    ACCESS_DENIED_TO_PAGE: '$t(ACCESS_DENIED_PREFIX) $t(THIS_PAGE).',
    ACCESS_DENIED_TO_PRODUCT: '$t(ACCESS_DENIED_PREFIX) $t(THIS_PRODUCT).',
    ACCESS_DENIED_TO_WORLD: '$t(ACCESS_DENIED_PREFIX) $t(THIS_WORLD).',
    CONTACT_ADMIN_MESSAGE: 'Para obtener más información, por favor comuníquese con vuestro administrador del sistema.',
    RETURN_TO_PRODUCT_SELECTION: 'Volver a la selección de productos',
    RETURN_TO_WORLD_SELECTION: 'Volver a la selección de mundos',
  },
  worldSelection: {
    TITLE: 'Selección de mundos',
    SELECT_A_WORLD: `Por favor seleccione el mundo que quiere ver`
  },
  editRecord: {
    TITLE: "Editar registro",
    SUBHEADER: "Editar el número de inventario, la ubicación de inicio y los grupos de este dispositivo.",
    ERROR_MESSAGE: 'Se produjo un error en la actualización del siguiente valor: {{fields, list}}. Inténtela de nuevo.',
    ERROR_MESSAGE_other: 'Se produjo un error en la actualización de los siguiente valores: {{fields, list}}. Inténtela de nuevo.',
    ASSET_TAG_VALIDATION_ERR: "Los números de inventarios deben tener un máximo de {{maxLength}} caracteres.",
    GROUP_VALIDATION_ERR_COMMAS: 'Los grupos no deben incluir comas.',
    GROUP_VALIDATION_ERR_LENGTH: 'Los grupos deben tener un máximo de {{maxLength}} caracteres.',
    NO_HOME_LOCATION: '$t(translation:NO_HOME_LOCATION)',
    GROUP_REMOVE: 'Haga clic para eliminar el grupo',
    GROUP_PLACEHOLDER: 'Agregar un grupo'
  },

  editEvents: {
    // EDIT EVENTS MODAL
    "OPEN_BUTTON": "Editar eventos",
    "FILTER_BUTTON": "Filtrar eventos",
    "DISPLAY_ALL": "Mostrar todo",
    "ICON": "Icono",
    "APPLICATIONS_GROUP": "Aplicación",
    "BATTERY_GROUP": "Batería ",
    "POWER_GROUP": "Potencia",
    "USER_EX_GROUP": "Experiencia del usuario",
    "NETWORK": "La red",
    "APPLY_BUTTON": "Aplicar",
    "OR": "o",
    "TIME_GROUP": "Sincronización de la hora",
    "MODAL_HEADER": "Mostrar Marcadores de Evento",
    "MODAL_TABLE_HEADER": "Filtrar la Lista de Eventos",
    "MODAL_SUBHEADER": "Editar los tipos de eventos que se muestran en los gráficos de rendimiento",
    "MODAL_TABLE_SUBHEADER": "Filtrar los eventos que se muestran en la lista de eventos de la página de rendimiento.",
    "EDITING": "Editando",
    "APPLY_ALL_BUTTON": "Aplicar a todos los gráficos",
    "RADIO_GROUP": "Radio",

    // EDIT EVENTS MODAL DEVICE EVENTS
    "APPLICATION_INSTALLED": "Instalación de una aplicación",
    "APPLICATION_UNINSTALLED": "Desinstalación de una aplicación",
    "APPLICATION_UPDATED": "Actualización de una aplicación",
    "BATTERY_SWAP": "Intercambio de Batería",
    "DEVICE_ON_POWER": "Con carga",
    "DEVICE_OFF_POWER": "Sin carga",
    "DEVICE_LOW_POWER": "Potencia baja",
    "DEVICE_REBOOTED": "Reinicio",
    "DEVICE_DROP": "Caida",
    "TIME_ERROR": "Error de la hora del dispositivo",
    "TIME_RECOVERY": "Recuperación de hora correcta",
    "NETWORK_AVAILABLE": "Red disponible",
    "NETWORK_LOST": "Red perdida",
    "NETWORK_CHANGED": "Cambio de red",
    "BEARER_CHANGED": "Cambio de portador",
    "SIM_CHANGED": "Cambio de SIM",
    "AIRPLANE_MODE_OFF": "Modo avión apagado",
    "AIRPLANE_MODE_ON": "Modo avión encendido",
    "MOBILE_RADIO_OFF": "Radio móvil apagada",
    "MOBILE_RADIO_ON": "Radio móvil encendida"
  },
  "formControls": {
    "DELETE": "Borrar",
    "DELETE_other": "Borrar ({{count}})",
    "SAVE": "Guardar",
    "SAVE_other": "Guardar ({{count}})",
    "REVIEW": "Examinar",
    "REVIEW_other": "Examinar ({{count}})",
    "APPLY": "Aplicar",
    "APPLY_other": "Aplicar ({{count}})",
  },
  devicesList: {
    "DEVICE_ACROSS": "dispositivo a través de",
    "DEVICE_ACROSS_other": "dispositivos a través de",
    "ELEMEZ_COREv2_CATEGORIES": "todas la categorías",
  },
  deleteDialogue: {
    CONFIRMATION_QUESTION: "Está seguro?",
    CANCEL_DELETION: "No, cancelar la eliminación",
    ERROR: "$t(error:SOMETHING_WRONG_RETRY)",
    ERROR_CUSTOM_DELETE: "$t(error:SOMETHING_WRONG) Es posible que no se haya borrado uno o más {{ item, lowercase }}. Inténtelo de nuevo."
  },
  deleteSmartBatteries: {
    SUBHEADER: "{{count}} batería será borrada.",
    SUBHEADER_other: "{{count}} baterías serán borradas.",
    MESSAGE: "Ya no podrá ver esta batería y su análisis individual.",
    MESSAGE_other: "Ya no podrá ver estas baterías y sus análisis individuales.",
    CONFIRM_BATTERIES_DELETE: "Borrar la batería",
    CONFIRM_BATTERIES_DELETE_other: "Borrar las baterías"
  },
  deleteDevices: {
    SUBHEADER: "{{count}} dispositivo será borrado.",
    SUBHEADER_other: "{{count}} dispositivos serán borrados.",
    MESSAGE: "Ya no podrá ver este dispositivo y su análisis individual.",
    MESSAGE_other: "Ya no podrá ver estos dispositivos y sus análisis individuales.",
    CONFIRM_DELETE: "Sí, borrar este dispositivo",
    CONFIRM_DELETE_other: "Sí, borrar estos dispositivos"
  },
  deleteUsers: {
    SUBHEADER: "{{count}} usuario será borrado.",
    SUBHEADER_other: "{{count}} usuarios serán borrados.",
    MESSAGE: "Ya no podrá ver este usuario y él no podrá acceder al sitio web.",
    MESSAGE_other: "Ya no podrá ver estos usuarios y ellos no podrán acceder al sitio web.",
    CONFIRM_DELETE: "Sí, borrar este usuario",
    CONFIRM_DELETE_other: "Sí, borrar estos usuarios"
  },
  deleteHomeLocations: {
    SUBHEADER_SINGLE: "‘{{ location }}’ será borrado.",
    SUBHEADER: "{{ count }} ubicación de inicio será borrada.",
    SUBHEADER_other: "{{ count }} ubicaciones de inicio serán borradas.",
    MESSAGE: "Ya no podrá asignar dispositivos o usuarios a esta ubicación de inicio.",
    MESSAGE_other: "Ya no podrá asignar dispositivos o usuarios a estas ubicaciones de inicio.",
    CONFIRM_DELETE: "Sí, borrar esta ubicación de inicio",
    CONFIRM_DELETE_other: "Sí, borrar estas ubicaciones de inicio",
    FAILURE_HEADER: "No se puede borrar la ubicación de inicio",
    FAILURE_HEADER_other: "No se pueden borrar las ubicaciones de inicio",
    FAILURE_HEADER_DEVICES: "La siguiente ubicación de inicio no se puede borrar porque tiene dispositivos asignados:",
    FAILURE_HEADER_DEVICES_other: "Las siguientes ubicaciones de inicio no se pueden borrar porque tienen dispositivos asignados:",
    FAILURE_FOOTER_DEVICES: "Se puede cambiar la ubicación de inicio de estos dispositivos individualmente o como una acción masiva.",
    FAILURE_HEADER_USERS: "La siguiente ubicación de inicio no se puede borrar porque tiene usuarios asignados:",
    FAILURE_HEADER_USERS_other: "Las siguientes ubicaciones de inicio no se pueden borrar porque tienen usuarios asignados:",
    FAILURE_FOOTER_USERS: "Se puede cambiar las ubicaciones de inicio de estos usuarios individualmente.",
    FAILURE_HEADER_ZONE: "La siguiente ubicación de inicio no se puede eliminar porque está asignada a una Zona:",
    FAILURE_HEADER_ZONE_other: "Las siguientes ubicaciones de inicio no se pueden eliminar porque están asignadas a una Zona:",
    FAILURE_FOOTER_ZONE: "Puede eliminar ubicaciones de inicio de estas zonas individualmente.",
    VIEW_BULK_ACTIONS: "Ver Acciones masivas"
  },
  timeState: {
    TODAY: `Hoy`,
    YESTERDAY: "Ayer",
    HOUR: "hora",
    HOUR_other: "horas",
    MIN: "min",
    MINUTE: "minuto",
    MINUTE_other: "minutos",
    HOURS_24: "24 Horas",
    HOURS_48: "48 Horas",
    DAYS_7: "7 Días",
    LESS_THAN_A_MONTH: "Menos de un mes",
    MONTH: "mes",
    MONTH_other: "meses",
    YEAR: "año",
    YEAR_other: "años",
    HOUR_COUNT: '{{ count }} Hora',
    HOUR_COUNT_other: '{{ count }} Horas',
    DAY_COUNT: '{{ count }} Día',
    DAY_COUNT_other: '{{ count }} Días',
    OVER_THE_LAST_DAYS: 'en los últimos {{ count }} días',
    IN_THE_LAST_F: 'en la última',
    IN_THE_LAST_F_other: 'en las últimas',
    IN_THE_LAST_M: 'en el último',
    IN_THE_LAST_M_other: 'en los últimos',
    LAST_N_DAYS: 'Últimos $t(timeState:DAY_COUNT_other, { "count": {{count}} })',
    LAST_N_HOURS: 'Últimos $t(timeState:HOUR_COUNT_other, { "count": {{count}} })',
    MORE_THAN_90_DAYS_AGO: 'Hace más de 90 días',
    OVER_THE_PAST_X_DAYS: 'en los últimos {{ count }} días',
    OVER_THE_PAST_X_HOURS: 'en las últimas {{ count }} horas'
  },
  distance: {
    METRE: 'metro',
    METRE_other: 'metros',
    KILOMETRE: 'kilómetro',
    KILOMETRE_other: 'kilómetros',
    YARD: 'yarda',
    YARD_other: 'yardas',
    MILE: 'milla',
    MILE_other: 'millas',
    SHORT_metric: '$t(distance:METRE)',
    SHORT_imperial: '$t(distance:YARD)',
    SHORT_metric_other: '$t(distance:METRE_other)',
    SHORT_imperial_other: '$t(distance:YARD_other)',
    LONG_metric: '$t(distance:KILOMETRE)',
    LONG_imperial: '$t(distance:MILE)',
    LONG_ABBREVIATION_imperial: 'mi',
    LONG_ABBREVIATION_metric: 'km',
    LONG_metric_other: '$t(distance:KILOMETRE_other)',
    LONG_imperial_other: '$t(distance:MILE_other)'
  },
  performance: {
    "DEVICE_PERFORMANCE_DESCRIPTION": "Informes configurables sobre eventos y uso reciente de este dispositivo.",
    "VIEW_LATEST_RECORDS": "Mostrar los últimos registros",
    "OR": "o",
    "SELECT_DATE": "Seleccione una fecha",
    "LAST_24HOURS_TEXT": "Las últimas 24 horas",
    "LAST_30DAYS_TEXT": "Los últimos 30 días",
    "LAST_KNOWN_BATTERY": "Ultima batería conocida",
    "LAST_KNOWN_BATTERIES": "Ultimas baterías conocidas",
    "PRINT_REPORT": "Imprime el informe",
    "NOW": "Ahora",
    "AT": "en",
    "WIFI_DATA_USED": "$t(translation:WIFI_DATA_USED)",
    "MOBILE_DATA_USED": "$t(translation:MOBILE_DATA_USED)",
    "DROPS": "Caidas",
    "REBOOTS": "Reinicia",
    "LOW_POWER": "Eventos de menor potencia",
    "BATTERY_SWAPS": "Intercambios de Baterías",
    "TIME_ERRORS": "Errores de la hora del dispositivo",
    "IN_USE": "En uso",
    "DEVICE_MINUTES_USAGE_PER_HOUR": "Uso del dispositivo en minutos por hora",
    "LESS_THAN_ONE_MINUTE_USAGE": "Menos de un minuto de uso",
    "DEVICES_USED": "Dispositivos de uso",
    "MOBILE_DATA": "Datos móviles",
    "WIFI_DATA": "Datos Wi-Fi",
    "MOBILE_DATA_LINES": "Datos\n móviles",
    "WIFI_DATA_LINES": "Datos\n Wi-Fi",
    "TOTAL_DATA": "Datos totales",
    "DATA_USAGE_HOUR_WIFI_MOBILE": "Uso de datos por hora (Wi-Fi + Móvil)",
    "DEVICE_CHARGE_LEVEL": "Nivel de carga del dispositivo",
    "CHARGE_LEVEL": "Nivel de carga",
    "BATTERY_SN": "Batería SN",
    REBOOT_TYPE_USER: 'Iniciado por el usuario',
    REBOOT_TYPE_SYSTEM: 'Iniciado por el sistema',
    "DEVICE_DATA_USAGE_PAST_30DAYS": "Uso de datos del dispositivo en los últimos 30 días",
    "DEVICE_UTILISATION_PAST_30_DAYS": "Minutos de uso del dispositivo en los últimos 30 días",
    "AVG_HOURLY_DISCHARGE": "Descarga promedio por hora",
    "AVG_HOURLY_DISCHARGE_LINES": "Descarga \n cada hora \n promedio",
    "AVERAGE_HOURLY_DISCHARGE_PAST_30DAYS": "Descarga promedio por hora durante los últimos 30 días",
    "EVENTS_HEADER": "Evento ({{count}})",
    "EVENTS_HEADER_other": "Eventos ({{count}})",
    "EVENTS_FOOTER": "evento detectados durante este tiempo",
    "EVENTS_FOOTER_other": "eventos detectados durante este tiempo",
    "SHOW_EVENTS_TEXT": "Mostrar Lista de Eventos",
    "HIDE_EVENTS_TEXT": "Ocultar Lista de Eventos",
    "EVENT_TYPE": "Tipo de Evento",
    "EVENT_TIME": "Hora de Evento",
    "EVENT_INFORMATION": "Información de Evento",
    "UPDATED_VERSION_TEXT": "actualizado a la versión ",
    "EVENTS_SHOWN": "Eventos mostrados",
    "ALL_EVENTS": "Todos los Eventos",
    "NONE": "ninguno",
    "UNINSTALLED_TEXT": "desinstalado",
    "INSTALLED_TEXT": "instalado",
    "TOOLTIP_TEXT": "Mostrar la lista de eventos para todos",
    "OUT": "Retirada",
    "IN": "Puesta",
    "OLD": "Vieja",
    "NEW": "Nueva",
    "RESET_ZOOM": "Reajustar el zoom",
    PERFORMANCE_CHART_TITLE_DEVICES_USAGE: `Uso de '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_DEVICES_USAGE_withFilter: `Uso de '{{ filter }}' $t(translation:DEVICE_other) {{ period }}`,
    PERFORMANCE_CHART_TITLE_DATA_USAGE: `Uso de datos de '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_REBOOTS: `Reinicios de '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_LOW_POWER: `Eventos de baja potencia de '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_TIME_ERRORS: `Errores de tiempo de '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_BATTERY_SWAPS: `Cambios de batería de '{{ filter }}' {{ period }}`,
    PERFORMANCE_CHART_TITLE_DROPS: `Caidas de '{{ filter }}' {{ period }}`
  },
  deviceLocation: {
    "DEVICE_LOCATION": "Ubicación del dispositivo",
    "DEVICE_LOCATION_DESCRIPTION": "Localice su dispositivo y su ubicación de inicio. Actualizado:",
    "MAP_KEY": "Clave de mapa:",
    "THIS_DEVICE": "Este dispositivo",
    "DEVICE_ACCURACY_RADIUS": "Radio de precisión de la ubicación del dispositivo",
    "HOME_LOCATION_RADIUS": "Radio de ubicación de inicio",
    "LOCATION_UNKNOWN": "Ubicación desconocida",
    "ACCURACY": "Precisión",
    "SOURCE": "Fuente"
  },
  deviceProcesses: {
    "DEVICE_PROCESS": "Proceso",
    "DEVICE_PROCESS_other": "Procesos",
    //Title
    "PROCESSES_TITLE": "Procesos del dispositivo",
    "PROCESSES_SUBTITLE": "Seleccione un proceso de la lista siguiente para ver su historial, uso y eventos.",
    "PROCESSES_ON_DEVICE": "Procesos en este dispositivo",
    //table headings
    "PROCESS_NAME": "Nombre del proceso",
    "PRODUCT_NAME": "Nombre del producto",
    "PRODUCT_VERSION": "Versión del producto",
    "FILE_VERSION": "Versión del archivo",
    //footer statement
    "DEVICE_PROCESS_TOTAL": "proceso en este dispositivo",
    "DEVICE_PROCESS_TOTAL_other": "procesos en este dispositivo",
    //overview labels
    "DETECTED_ON_DEVICE": "detectado en funcionamiento en este dispositivo",
    "DETECTED_ON_DEVICE_other": "detectados en funcionamiento en este dispositivo",
    "USED_PROCESSES": "utilizado en los últimos {{ days }} días",
    "USED_PROCESSES_other": "utilizados en los últimos {{ days }} días",
    //search
    "USED_TODAY": "utilizado hoy",
    "USED_TODAY_other": "utilizados hoy",
    "USAGE": "Uso",
    "CPU_USAGE": "Uso de CPU",
    "CPU": "$t(translation:CPU)",
    "USAGE_TITLE_daily": "Uso diario {{ timePeriod }}",
    "USAGE_TITLE_hourly": "Uso por hora {{ timePeriod }}",
    "CPU_USAGE_TITLE_daily": "Uso diario de CPU {{ timePeriod }}",
    "CPU_USAGE_TITLE_hourly": "Uso por hora de CPU {{ timePeriod }}"
  },
  applications: {
    "SHARED_ID": "Identificación de usuario compartida",
    "SHARED_ID_TOOLTIP_TEXT": "Una identificación de usuario compartida permite que grupos de aplicaciones relacionadas funcionen juntos. Las aplicaciones con una identificación de usuario compartida también comparten los mismos valores para el uso y el tamaño de datos.",
    "APPLICATION": "aplicación",
    "APPLICATION_other": "aplicaciones"
  },
  deviceApplications: {
    "OVERVIEW_TITLE": "Resumen de las aplicaciones",
    "OVERVIEW_SUBTITLE": "Seleccione una aplicación de la lista siguiente para ver su historial, uso y eventos.",
    "APPLICATION_ON_DEVICE": "Aplicación en este dispositivo",
    "APPLICATION_ON_DEVICE_other": "Aplicaciones en este dispositivo",
    get APPLICATION_ON_DEVICE_OVERVIEW() { return this.APPLICATION_ON_DEVICE.toLowerCase(); },
    get APPLICATION_ON_DEVICE_OVERVIEW_other() { return this.APPLICATION_ON_DEVICE_other.toLowerCase(); },
    "DEVICE_APPLICATION_TOTAL": "aplicación en este dispositivo",
    "DEVICE_APPLICATION_TOTAL_other": "aplicaciones en este dispositivo",
    "INSTALLED_APPLICATIONS": "instalada en los últimos 30 días",
    "INSTALLED_APPLICATIONS_other": "instaladas en los últimos 30 días",
    "UPDATED_APPLICATIONS": "actualizada en los últimos 30 días",
    "UPDATED_APPLICATIONS_other": "actualizadas en los últimos 30 días",
    "USED_APPLICATIONS": "utilizada en los últimos 14 días",
    "USED_APPLICATIONS_other": "utilizadas en los últimos 14 días",
    "USED_TODAY": "utilizada hoy",
    "USED_TODAY_other": "utilizadas hoy",
    "SHARED_ID": "$t(applications:SHARED_ID)",
    "SHARED_ID_TOOLTIP_TEXT": "$t(applications:SHARED_ID_TOOLTIP_TEXT)",
    "DAILY_USAGE_PAST_30DAYS": "Uso diario en los últimos 30 días",
    "DATA_USAGE_TITLE_daily": "Uso diario de datos {{ timePeriod }}",
    "DATA_USAGE_TITLE_hourly": "Uso de datos por hora {{ timePeriod }}",
    "PACKAGE_NAME": "Nombre del paquete"
  },
  coreApplications: {
    "NAME": "Nombre",
    "SHARED_ID": "$t(applications:SHARED_ID)",
    "SHARED_ID_TOOLTIP_TEXT": "$t(applications:SHARED_ID_TOOLTIP_TEXT)",
    "VERSION_COUNT": "Versiones",
    "INSTALLED_COUNT": "Instancias",
    "CURRENT_VERSION_COUNT": "Versiones actuales",
    "CURRENT_INSTALLED_COUNT": "Instancias actuales",
    "LATEST_VERSION": "Última versión",
    "LAST_USED": "$t(translation:LAST_USED)",
    "USED_COUNT": "Número de dispositivos que la utilizan",
    "USED_COUNT_TOOLTIP_TEXT": "Número de dispositivos que han utilizado esta aplicación en los últimos 30 días.",
    "FIRST_INSTALLED": "Primera instalación",
    "MOBILE_DATA_USED": "$t(translation:MOBILE_DATA_USED)",
    "TOTAL_MINUTES_USAGE": "Total de minutos de utilización",
    "WIFI_DATA_USED": "$t(translation:WIFI_DATA_USED)",
    "AVERAGE_MINUTES_USED": "Utilización media diaria",
    "VERSION_FILTER": " con la versión {{ version }} de esta aplicación",
    "GROUP_FILTER": "todos los dispositivos del grupo {{ name }}",
    "ZONE_FILTER": " todos los dispositivos de la zona {{ name }}",
    "HOME_LOCATION_FILTER": "todos los dispositivos de la ubicación de inicio {{ name }}",
    "NO_GROUP_FILTER": "todos los dispositivos sin grupo",
    "NO_HOME_LOCATION_FILTER": "todos los dispositivos sin ubicación de inicio",
    "SECTION_TITLE_OVERVIEW": `Desglose de la aplicación de {{ filter }}`,
    "SECTION_TITLE_OVERVIEW_withVersion": `Desglose de la aplicación de {{ filter }}{{versionFilter}}`,
    "SECTION_TITLE_DATA_USAGE": `Uso de datos de la aplicación de {{ filter }}`,
    "SECTION_TITLE_DATA_USAGE_withVersion": `Uso de datos de la aplicación de {{ filter }}{{versionFilter}}`,
    "SECTION_TITLE_DEVICE_USAGE": `Utilización de la aplicación de {{ filter }}`,
    "SECTION_TITLE_DEVICE_USAGE_withVersion": `Utilización de la aplicación de {{ filter }}{{versionFilter}}`,
    "SECTION_TITLE_MINUTES_USAGE": `Minutos de uso de la aplicación de {{ filter }}`,
    "SECTION_TITLE_MINUTES_USAGE_withVersion": `Minutos de uso de la aplicación de {{ filter }}{{versionFilter}}`,
    "SECTION_TITLE_APPLICATION_DEVICES": `{{ filter }}`,
    "SECTION_TITLE_APPLICATION_DEVICES_withVersion": `{{ filter }}{{versionFilter}}`,
    "ALL_DEVICES": `Todos los dispositivos`,
    get ALL_DEVICES_lowercase() { return `${this.ALL_DEVICES.toLowerCase()}`; },
    "PERFORMANCE": "Rendimiento",
    "DEVICES_USED": "Número de dispositivos que han utilizado la aplicación",
    "MINUTES_USED": "Minutos de uso",
    "ALL_APPLICATIONS": "Todas las aplicaciones",
    "USER_APPLICATIONS": "Aplicaciones del usuario",
    "SYSTEM_APPLICATIONS": "Aplicaciones del sistema",
    "ALL_APPLICATION_VERSIONS": "Todas",
    "APP_VERSION": "Versión de la aplicación",
    "APP_VERSION_filter": "Versión de la aplicación:",
    "APPLICATION_DEVICES_LIST_TITLE": "$t(translation:DEVICES_LIST_TITLE)",
    "APP_LAST_USED": "Ultimo uso de la aplicación",
    "MOBILE_DATA_FULL": "Aplicación $t(translation:MOBILE_DATA)",
    "WIFI_DATA_FULL": "Aplicación $t(translation:WIFI_DATA_USED)",
    "WIFI_DATA_TOOLTIP_TEXT": "Los datos Wi-Fi utilizados por esta aplicación en el dispositivo en los últimos 30 días.",
    "MOBILE_DATA_TOOLTIP_TEXT": "Los datos móviles utilizados por esta aplicación en el dispositivo en los últimos 30 días.",
    "VERSIONS_TOOLTIP": "Lista de versiones de la aplicación que han comunicado con Elemez durante el período de tiempo seleccionado",
    "SHARED_USER_ID_INFO": "Los valores de utilización de datos que aparecen aquí y en el gráfico de utilización de datos en esta página son valores agregados de todas las aplicaciones y sus versions que utilizan la identificación de usuario compartida \"{{ sharedUserId }}\"",
    "SECTION_TITLE_SHARED_USER_ID": `Utilización de datos agregados de {{ filter }} que utilizan la identificación de usuario compartida "{{ sharedUserId }}"`
  },
  coreProcesses: {
    "PROCESS": "Proceso",
    "DEVICE_LIST": "Lista de dispositivos",
    "PROCESS_other": "Procesos",
    "PROCESSES_TITLE": "Procesos de Windows",
    "PROCESS_NAME": "Nombre del proceso",
    "PRODUCT_NAME": "Nombre del producto",
    "SUBHEADER": "Procesos vistos ejecutándose en el patrimonio en los últimos 30 días",
    "ACTIVE_DEVICES": "Dispositivos activos",
    "VERSIONS": "Versiones",
    "LATEST_PRODUCT_VERSION": "Última versión del producto",
    "LATEST_FILE_VERSION": "Última versión del archivo",
    "USED_DEVICES": "Dispositivos utilizados",
    "LAST_USED": "$t(translation:LAST_USED)",
    "ACTIVE_DEVICES_TOOLTIP_TEXT": "La cantidad de dispositivos donde se ha visto ejecutándose esta instancia de proceso en los últimos 30 días.",
    "USED_COUNT_TOOLTIP_TEXT": "La cantidad de dispositivos donde esta instancia de proceso ha tenido alguna interacción de usuario en los últimos 30 días.",
    "PERFORMANCE": "Actuación",
    "ALL_PROCESS_VERSIONS": "$t(translation:ALL)",
    "DEVICES_USED": 'Dispositivos utilizados',
    "ALL_DEVICES": `Todos los dispositivos`,
    get ALL_DEVICES_lowercase() { return `${this.ALL_DEVICES.toLowerCase()}`; },
    "GROUP_FILTER": "dispositivos en grupo {{ name }}",
    "ZONE_FILTER": "dispositivos en zona {{ name }}",
    "HOME_LOCATION_FILTER": "dispositivos en la ubicación de inicio {{ name }}",
    "NO_GROUP_FILTER": "dispositivos sin grupos",
    "NO_HOME_LOCATION_FILTER": "dispositivos sin ubicación de inicio",
    "VERSION_FILTER": "con la versión {{ version }} de este proceso",
    "SECTION_TITLE_CPU_USAGE": `Procesar el uso de la CPU en {{ filter }}`,
    "SECTION_TITLE_CPU_USAGE_withVersion": `Procesar el uso de la CPU en {{ filter }}{{versionFilter}}`,
    "SECTION_TITLE_USAGE": `Uso de procesos en {{ filter }}`,
    "SECTION_TITLE_USAGE_withVersion": `Uso de procesos en {{ filter }}{{versionFilter}}`,
    "SECTION_TITLE_UTILIZATION": `Utilización de procesos en {{ filter }}`,
    "SECTION_TITLE_UTILIZATION_withVersion": `Utilización de procesos en {{ filter }}{{versionFilter}}`,
    "DEVICE_LIST_TITLE": `{{filter}} con este proceso`,
    "DEVICE_LIST_TITLE_withVersion": `{{filter}}{{versionFilter}}`,
    "MINUTES_USED": `Minutos Usados`,
    "CPU_MINUTES_USED": 'Minutos de CPU Utilizados',
    "VERSION_TOOLTIP": "Lista de combinaciones de versión de producto/versión de archivo vistas durante el período de tiempo seleccionado"
  },
  alerts: {
    "NO_ALERTS": "No hay problemas detectados actualmente en este dispositivo",
    "DEVICE_LOCATION_DISTANCE_STATUS_metric": "Este dispositivo ha terminado <1>{{threshold}} $t(distance:KILOMETRE_other)</1> desde la ubicación de inicio",
    "DEVICE_LOCATION_DISTANCE_STATUS_imperial": "Este dispositivo ha terminado <1>{{threshold}} $t(distance:MILE_other)</1> desde la ubicación de inicio",
    "DEVICE_LOCATION_TIME_STATUS": "Este dispositivo ha estado lejos de su ubicación de inicio por más de <1>{{threshold}} horas</1>",
    "NO_LOCATION_ALERT": "No se han detectado problemas de ubicación en este dispositivo",
    "NO_UTILISATION_ALERT": "No se han detectado problemas de uso en este dispositivo",
    "NO_USER_EXPERIENCE_ALERT": "No se han detectado problemas de experiencia de usuario en este dispositivo",
    "DEVICE_UTILISATION_OUT_OF_CONTACT_HOUR_ZERO_STATUS": "Este dispositivo ha estado <1>fuera de contacto</1> durante menos de una hora",
    "DEVICE_UTILISATION_OUT_OF_CONTACT_HOUR_STATUS": "Este dispositivo ha estado <1>fuera de contacto</1> desde {{hoursSpentOutOfContact}}hora",
    "DEVICE_UTILISATION_OUT_OF_CONTACT_HOUR_STATUS_other": "Este dispositivo ha estado <1>fuera de contacto</1> desde {{hoursSpentOutOfContact}} horas",
    "DEVICE_UTILISATION_OUT_OF_CONTACT_DAY_STATUS": "Este dispositivo es <1>fuera de contacto</1> desde el {{lastUpdated}} (<5>{{daysSpentOutOfContact}} día</5>)",
    "DEVICE_UTILISATION_OUT_OF_CONTACT_DAY_STATUS_other": "Este dispositivo ha estado <1>fuera de contacto</1> desde {{lastUpdated}} (<5>{{daysSpentOutOfContact}} días</5>)",
    "DEVICE_IDLE_DAY_STATUS": "Este dispositivo ha estado <1>inactif</1> por <3>{{configDays}}</3> o más <5>días</5>",
    "HOURS": "horas",
    "DAYS": "{{thresholdPeriod}} días",
    "DEVICE_REBOOT_STATUS": "Este dispositivo ha sido  <1>reiniciado {{deviceEvent}} vez</1> durante el pasado ",
    "DEVICE_REBOOT_STATUS_other": "Este dispositivo ha sido <1>reiniciado {{deviceEvent}} veces</1> durante el pasado ",
    "DEVICE_DROP_STATUS": "Este dipositivo  ha estado <1>caido {{deviceEvent}} una vez</1> durante el pasado ",
    "DEVICE_DROP_STATUS_other": "Este dispositivo ha estado<1>caido{{deviceEvent}} veces</1> durante el pasado ",
    "DEVICE_LOW_POWER_STATUS": "Este dispositivo ha tenido <1>{{deviceEvent}} un evento de potencia baja</1> durante el pasado ",
    "DEVICE_LOW_POWER_STATUS_other": "Este dispositivo ha tenido  <1>{{deviceEvent}} eventos de potencia baja</1> durante el pasado ",
    "TIME_ERROR_STATUS": "Este dispositivo tiene un error de hora",
    "AVG_DISCHARGE_STATUS": "Este dispositivo ha registrado un <1>promedio elevado de descarga de {{discharge}}%</1> durante los últimos 30 días",
    "THIS_DEVICE_IS": "Este dispositivo está",
    "ON_A_KNOWN_NETWORK": "en una red conocida",
    "ON_AN_UNKNOWN_NETWORK": "en una red desconocida",
    "AT_ACCESS_POINT_LOCATION_NAME": "en",
    "NOT_BELONGING_TO_HOME_LOCATION": "que no pertenece a su ubicación de inicio"
  },
  agreements: {
    EULA_FULL: `Contrato de licencia de usuario final`,
    THE_EULA_FULL: 'El contrato de licencia de usuario final',
    EULA_ABBREV: 'CLUF',
    THE_EULA_ABBREV: 'El CLUF',
    DPA_FULL: 'Acuerdo de procesamiento de datos',
    THE_DPA_FULL: `El acuerdo de procesamiento de datos`,
    DPA_ABBREV: 'APD',
    THE_DPA_ABBREV: 'El APD',
    BOTH_EULA_DPA: 'El CLUF y el APD',
    OPEN_IN_NEW_TAB: 'Abrir {{v}}en una nueva pestaña',
    AGREE_TERMS: `Acepto los términos y condiciones de la {{v}}`,
    AGREEMENT_DESCRIPTION: 'By accepting this agreement you warrant that you have the authority to accept and bind your organisation to its content. Clicking the Accept button is considered equivalent to your signature on this agreement.', //missing
    AGREEMENT_DESCRIPTION_other: 'By accepting these agreements you warrant that you have the authority to accept and bind your organisation to their content. Clicking the Accept button is considered equivalent to your signature on these agreements.',  //missing
    AGREEMENTS_READ: `Por favor<1> lea atentamente</1> {{v}} antes de aceptar:`,
    RETURN_LOG_IN: 'Volver al inicio de sesión',
    ACCEPT: 'Aceptar',
    ERROR: `$t(error:SOMETHING_WRONG_RETRY)`,
    EULA_AND_DPA_DESCRIPTION: 'Su {{v}}'
  },
  enrollment: {
    SECTION_TITLE: 'Inscripción',
    SECTION_SUBTITLE: 'Inscripción',
    TOKEN_HEADER: 'Código de Elemez',
    TOKEN_SUBHEADER: 'El código es necesario si está distribuyendo e instalando el agente de Elemez mediante un MDM o EMM.',
    NO_TOKEN_TEXT: 'No hay código disponible',
    TOKEN_COPY_SUCCESS: "Código copiado",
    TOKEN_COPY_FAIL: "Copia fallida"
  },
  batteryEssentials: {
    NO_SELECTION_HEADER: "Bienvenido a {{title}}",
    BATTERY_NOT_FOUND: "No se encontró ninguna batería que coincidiera con la información proporcionada",
    BATTERY_NOT_FOUND_BODY: "No se encontró la batería que busca. Seleccione una batería de la lista a continuación.",
    NO_SELECTION_BODY_UPPER: "Seleccione una batería de la lista siguiente para ver más información sobre su historial de estado y nivel de carga.",
    NO_SELECTION_BODY_LOWER: "Cada registro de batería va acompañado de información sobre el último dispositivo conocido en el que se informó que estaba.",
    NO_BATTERY_SELECTED: "Sin batería seleccionada",
    BATTERY_STATUS_HEADER: "Estado de la batería",
    BATTERY_IDENTIFIERS: "Identificadores de batería",
    PART_NUMBER: "Número de pieza",
    MANUFACTURE_DATE: "Fecha de fabricación",
    HEALTH: "Salud",
    CAPACITY: "Capacidad (mAh)",
    CYCLE_COUNT: "Ciclos de carga",
    AGE: "Edad",
    LAST_KNOWN_DEVICE: "Último dispositivo conocido",
    GROUPS_FILTER_HEADER: "Filtro de grupos",
    UNDER_WARRANTY_FILTER_HEADER: "Filtro de garantía",
    UNDER_WARRANTY: "Bajo garantía",
    CHARGE_LEVEL_STATEMENT: "Nivel de carga durante el {{timePeriod, lowercase}}",
    TIME_PERIOD: "Período de tiempo:",
    BATTERY_SERIAL_NUMBER: "Número de serie de la batería",
    BATTERY_PART_NUMBER: "Número de pieza de la batería",
    VIEW_IN_DEVICES: "Ver en Dispositivos",
    All_BATTERIES_TITLE: "Todas las baterías",
    GOOD_BATTERIES_TITLE: "Buenas baterías",
    REPLACE_BATTERIES_TITLE: "Baterías que necesitan ser reemplazadas",
    WARNING_BATTERIES_TITLE: "Baterías con una advertencia"
  },
  batteryEssentialsDeviceView: {
    NO_SELECTION_BODY: "Seleccione un dispositivo de la lista siguiente para ver más información sobre su estado y descarga media.",
    NO_DEVICE_SELECTED: "Sin dispositivo seleccionado",
    DEVICE_NOT_FOUND: "No se encontró ningún dispositivo que coincidiera con la información proporcionada",
    DEVICE_NOT_FOUND_BODY: "No se encontró el dispositivo que busca. Seleccione un dispositivo de la lista a continuación.",
    GROUPS_FILTER_HEADER: "Filtro de grupos",
    DEVICE_INFORMATION: "Información del dispositivo",
    HIGH_DISCHARGE: "Alta descarga",
    LAST_KNOWN_BATTERY: "Última batería conocida",
    LAST_KNOWN_BATTERIES: "Últimas baterías conocidas",
    VIEW_IN_BATTERIES: "Ver en Baterías",
    AVERAGE_HOURLY_DISCHARGE: "Promedio de descarga por hora",
    DEVICE_HOURLY_DISCHARGE_STATEMENT: "Descarga media por hora del dispositivo por día",
    DEVICE_DISCHARGE: "Descarga",
    AVERAGE_DISCHARGE: "Promedio de descarga",
    All_DEVICES_TITLE: "Todos los dispositivos",
    GOOD_DEVICES_TITLE: "Buenos dispositivos",
    WARNING_DEVICES_TITLE: "Dispositivos con una advertencia"
  },
  estateOverview: {
    DEVICES_UNAFFECTED: "Dispositivos no afectados",
    ALERTING_PERCENTAGE: "Porcentaje en estado de alerta",
    PERCENTAGE_AFFECTED: "Porcentaje afectado",
    DEVICES_AFFECTED: "Dispositivos afectados",
    PROBLEM_DEVICES: "Dispositivos problemáticos",
    WARNING_DEVICES: "Dispositivos en estado de alerta",
    GOOD_DEVICES: "Buenos dispositivos",
    TOTAL_DEVICES: "Total de dispositivos",
    DEFAULT_FILTER: "Cualquier tipo de alerta",
    ACCESS_POINT_FILTER: "Punto de acceso que no pertenece a la ubicación de su casa",
    TIME_ERROR_FILTER: "En error de tiempo",
    FILTER_TYPES: "Filtros de tipo de alerta",
    SUBTITLE: 'Grupos y ubicaciones de casas con "{{ text }}" alertas',
    NO_ALERTS: "No hay grupos ni ubicaciones de casa con alertas",
    HOME_LOCATIONS_AND_GROUPS: "ubicaciones y grupos de inicio",
    NO_GROUP: "$t(translation:NO_GROUP)",
    HOME_LOCATION_GROUP: "$t(translation:GROUP) / $t(translation:HOME_LOCATION)",
    TYPE: "Tipo",
    FILTER_TOGGLE_SUBTITLE: "Mostrar filtros de alertas",
  },
  alertOverview: {
    TITLE: 'Alertas ({{ alertCount }})',
    NO_ALERTS: 'No hay alertas en su flota',
    REBOOT_STATUS: 'tienen un número elevado de reinicios',
    TIME_ERR_STATUS: 'tienen un error de hora',
    AVR_DISCHARGE_STATUS: 'tienen un promedio elevado de descarga',
    LOW_POWER_STATUS: 'tienen un número elevado de eventos de baja potencia',
    LOCATION_DISTANCE_STATUS: 'están lejos de sus ubicaciones de inicio',
    LOCATION_TIME_STATUS: 'han estado lejos de su ubicación de origen por un tiempo',
    DROP_STATUS: 'tienen un número elevado de caídas',
    LOCATION_ACCESS_POINT_STATUS: 'están conectados a un punto de acceso que no pertenece a su ubicación de inicio',
    IDLE_STATUS: 'han estado inactivos por un tiempo',
    OUT_OF_CONTACT_STATUS: 'han estado fuera de contacto por un tiempo',
    OF_DEVICES: 'de los dispositivos'
  },
  error: {
    SOMETHING_WRONG: 'Lo siento, algo salió mal.',
    SOMETHING_WRONG_RETRY: 'Lo siento, algo salió mal. Inténtelo de nuevo.',
    RELOAD_PAGE: 'Recargar la página'
  },
  settings: {
    SAVE_CHANGES: '$t(translation:SAVE_CHANGES)',
    CLEAR_CHANGES: 'Borrar cambios',
    SAVE_SUCCESS: 'Actualizado con éxito',
    SAVE_FAIL: 'La operación ha fallado',
    LAST_DAYS: '$t(timeState:IN_THE_LAST_M_other)',
    LAST_HOURS: '$t(timeState:IN_THE_LAST_F_other)',
    REBOOTS_TITLE: 'Reinicios - Umbral global',
    REBOOTS_MESSAGE: 'reinicio',
    REBOOTS_MESSAGE_other: 'reinicios',
    REBOOTS_POPOVER: 'Se genera una alerta de reinicio cuando el número de reinicios del dispositivo es igual o supera el umbral dentro del período de tiempo especificado',
    OUT_OF_CONTACT_TITLE: 'Utilización - Umbral de falta de contacto',
    OUT_OF_CONTACT_MESSAGE: 'El dispositivo ha estado fuera de contacto durante',
    OUT_OF_CONTACT_POPOVER: 'Alerta que se genera cuando el número de días en que el dispositivo ha estado fuera de contacto supera el umbral',
    OUT_OF_CONTACT_UNIT: 'día',
    OUT_OF_CONTACT_UNIT_other: 'días',
    USAGE_TITLE: 'Utilización: umbral de inactividad',
    USAGE_MESSAGE: 'El dispositivo ha estado inactivo durante',
    USAGE_POPOVER: 'Alerta generada cuando el número de días en que el dispositivo está inactivo es igual o mayor al umbral',
    USAGE_UNIT: 'día',
    USAGE_UNIT_other: 'días',
    LOW_POWER_EVENTS_TITLE: 'Eventos de potencia baja: umbral global',
    LOW_POWER_EVENTS_MESSAGE: 'evento de potencia baja',
    LOW_POWER_EVENTS_MESSAGE_other: 'eventos de potencia baja',
    LOW_POWER_EVENTS_POPOVER: 'Se genera una alerta de potencia baja cuando el número de eventos de potencia baja iguala o supera el umbral dentro del período de tiempo especificado',
    DROPS_TITLE: 'Caídas - Umbral global',
    DROPS_MESSAGE: 'caída',
    DROPS_MESSAGE_other: 'caídas',
    DROPS_POPOVER: 'Se genera una alerta de caída cuando el número de caídas de dispositivos es igual o supera el umbral dentro del período de tiempo especificado',
    TIME_TITLE: 'Ubicación: registrar umbrar global',
    TIME_MESSAGE: 'No se ha detectado el dispositivo en su ubicación de inicio durante',
    TIME_POPOVER: 'Alerta generada cuando el tiempo en que el dispositivo ha estado fuera de su ubicación de inicio es igual o mayor al umbral',
    TIME_UNIT: '$t(timeState:HOUR)',
    TIME_UNIT_other: '$t(timeState:HOUR_other)',
    DISTANCE_TITLE: 'Ubicación: umbral global de distancia',
    DISTANCE_MESSAGE: 'El dispositivo está más lejos de',
    DISTANCE_POPOVER: `Alerta generada cuando la distancia del dispositivo desde su ubicación de inicio es igual o mayor al umbral`,
    DISTANCE_UNIT: `$t(distance:LONG, { "context": "{{context}}", "count": {{count}} }) de distancia de su ubicación de inicio`,
    BATTERY_WARNING_HEADER: 'Umbral de advertencia',
    BATTERY_REPLACE_HEADER: 'Umbral de reemplazo',
    BATTERY_HEALTH_TITLE: 'Umbrales de salud',
    BATTERY_HEALTH_POPOVER: `Alerta generada cuando el porcentaje de 'Salud' es iqual a o inferior al umbral.`,
    BATTERY_HEALTH_WARNING_ERROR: 'El umbral de advertencia debe estar entre 2 y 99 y ser mayor al umbral de sustitución',
    BATTERY_HEALTH_REPLACE_ERROR: 'Reemplazar umbral debe estar entre 1 y 98 y ser inferior al umbral de advertencia',
    BATTERY_HEALTH_ROW_TITLE: 'Todas las baterías que comunican la salud',
    BATTERY_CYCLE_COUNT_TITLE: 'Umbrales de ciclos de carga',
    BATTERY_CYCLE_COUNT_POPOVER: `Alerta generada cuando el la suma de los ciclos de carga es igual o mayor a cada umbral. Solo se la aplica a las baterías que no comunican 'Salud'.`,
    BATTERY_CYCLE_COUNT_WARNING_ERROR: 'El umbral de advertencia debe situarse entre 1 y 9998 y ser inferior al umbral de reemplazo',
    BATTERY_CYCLE_COUNT_REPLACE_ERROR: 'El umbral de reemplazo debe situarse entre 2 y 9999 y ser mayor al umbral de advertencia',
    BATTERY_CYCLE_COUNT_MANUFACTURER: '$t(translation:MANUFACTURER)',
    BATTERY_CYCLE_COUNT_PART: '$t(batteryEssentials:PART_NUMBER)',
    AVG_DISCHARGE_TITLE: 'Umbral de promedio de descarga del dispositivo',
    AVG_DISCHARGE_MESSAGE: 'Todos los dispositivos',
    AVG_DISCHARGE_POPOVER: 'Alerta generada cuando el promedio de descarga es igual o mayor al umbral.',
    AVG_DISCHARGE_ERROR: 'El umbral de advertencia debe situarse entre 1 y 99',
    PERIOD_IN_DAYS: 'Período (en días)',
    THRESHOLD_WARNING: 'Umbral de advertencia',
    THRESHOLD_PROBLEM: 'Umbral de problema',
    THRESHOLD_PERIOD_ERROR: 'Period Threshold should be between {{ min }} and {{ max }}',
    THRESHOLD_WARNING_ERROR: 'El umbral de advertencia debe situarse entre {{ min }} y {{ max }} y ser inferior al umbral de problema',
    THRESHOLD_PROBLEM_ERROR: 'El umbral de problema debe situarse entre {{ min }} y {{ max }} y ser mayor al umbral de advertencia',
    TAB_ALARMS: 'Alarmas',
    TAB_ALERTS: 'Alertas',
    TAB_BATTERY: 'Batería',
    TAB_LOCATION: 'Ubicación',
    TAB_UTILISATION: 'Utilización',
    TAB_UX: 'Experiencia de usuario',
    MAIN_TITLE: '$t(translation:SETTINGS)',
    ALERT_ACTIVE: "Alerta activa",
    BATTERY_ALERTS: "Alertas de batería",
    HOME_LOCATION_WARNING_ALERT_TOOLTIP_TEXT: "Alerta de advertencia generada cuando un dispositivo está conectado a una red conocida que no pertenece a su ubicación de inicio asignada.",
    HOME_LOCATION_PROBLEM_ALERT_TOOLTIP_TEXT: "Alerta de problema generada cuando un dispositivo está conectado a una red desconocida.",
    HOME_LOCATION_NETWORK_ALERT: "Alerta de la red de la ubicación de inicio",
    HOME_LOCATION_NETWORK_ALERT_WARNING_TEXT: "El dispositivo está conectado a una red conocida, que no pertenece a su propia ubicación de inicio.",
    HOME_LOCATION_NETWORK_ALERT_PROBLEM_TEXT: "El dispositivo está conectado a una red desconocida.",
    PROBLEM_ALERT: "Alerta de problema:",
    WARNING_ALERT: "Alerta de advertencia:",
    DAILY_RUN_TIME: "Tiempo de funcionamiento diario:",
    ANOMALY_DETECTION: "Detección de Anomalías",
    LOCATION_INFORMATION_TITLE: "Recopilación e informes de información de ubicación",
    LOCATION_WINDOWS_ENABLED: "Habilitado en dispositivos Windows",
    LOCATION_ANDROID_ENABLED: "Habilitado en dispositivos Android",
    DROPS_ENABLED_TITLE: "Detección de caídas",
    DROPS_ANDROID_ENABLED: "Habilitado en dispositivos Android",
    ENABLE_ANOMALY_DETECTION: "Habilitar Detección de Anomalías",
    SELECT_HOUR_LABEL: "Seleccione la hora",
    SELECT_AM_OR_PM_LABEL: "Seleccione AM o PM",
    SELECT_TIME_ZONE_LABEL: "Sélectionnez la zona horaria",
    PLATFORM_DEVICE_IDENTIFIER_TITLE: "Identificador de dispositivo principal",
    PLATFORM_DEVICE_IDENTIFIER_INFORMATION: "Seleccione el valor principal por el que identifica los dispositivos en su flota de dispositivos.",
    PLATFORM: "Plataforma",
    LOCATION_INFO_QUESTION: "Confirmación de Configuración de Ubicación",
    LOCATION_INFO_TURNING_OFF: 'Al desactivar la recopilación y los informes de datos de ubicación, los datos históricos de ubicación que ya se hayan recopilado no se eliminarán automáticamente. Comuníquese con el soporte de B2M si necesita que se eliminen los datos históricos de ubicación.',
    LOCATION_INFO_TURNING_ON: 'Al habilitar la recopilación y generación de informes de datos de ubicación del dispositivo, usted acepta el procesamiento de datos de ubicación para dispositivos en su mundo. Asegúrese de estar autorizado para brindar este consentimiento en nombre de su organización y de que el registro de datos de ubicación del dispositivo esté legalmente permitido en las jurisdicciones y dispositivos cubiertos por este servicio. También tenga en cuenta que, si desactiva la recopilación de datos de ubicación en el futuro, los datos históricos de ubicación que ya se hayan recopilado no se eliminarán automáticamente. Comuníquese con el soporte de B2M si necesita que se eliminen los datos históricos de ubicación.'
  },

  maps: {
    POOR_NO_SIGNAL_TAB: 'Mala señal/sin señal',
    POOR_NO_SIGNAL_HEADING: 'Áreas con mala señal o sin señal',
    POOR_NO_SIGNAL_SUBHEADING: 'Identificar áreas donde los dispositivos tienen más probabilidades de recibir una señal deficiente o ninguna señal',
    POOR_NO_SIGNAL_INTENSITY: 'Medicións deficientes o sin señal',
    LOCATION: 'Ubicación',
    TOTAL_COUNT: 'Medicións totales',
    CONFIG_GRID_SHOWN: 'Cuadrícula mostrada',
    CONFIG_GRAYSCALE: 'Escala de grises',
    CONFIG_LEGEND: 'Leyenda',
    CONFIG_TOOLTIP: 'Información sobre herramientas al pasar el mouse',
    CONFIG_DISCRETE: 'Mostrar bandas',
    NO_SERVICE_HEADING: 'Áreas sin servicio',
    NO_SERVICE_TAB: 'Sin servicio',
    NO_SERVICE_SUBHEADING: 'Identificar áreas donde los dispositivos tienen más probabilidades de no tener servicio',
    NO_SERVICE_INTENSITY: 'Sin lecturas de servicio',
    COVERAGE_HEADING: "Áreas de cobertura",
    COVERAGE_INTENSITY: "Lecturas de cobertura",
    COVERAGE_SUBHEADING: "Uso de la red por proveedor de SIM y tipo de red",
    COVERAGE_TAB: "Cobertura",
    ALL_SIM_PROVIDERS: "Todos los proveedores de SIM"
  },

  alarmThresholds: {
    DEVICE_STATUS_ALARM: 'Cualquier tipo de alerta',
    DEVICE_LOCATION_TIME_STATUS_ALARM: `$t(filters:DEVICE_LOCATION_TIME_STATUS_RED)`,
    DEVICE_TIME_ERROR_STATUS_ALARM: `$t(filters:DEVICE_TIME_ERROR_STATUS_RED)`,
    DEVICE_LOCATION_DISTANCE_STATUS_ALARM: `$t(filters:DEVICE_LOCATION_DISTANCE_STATUS_RED)`,
    AVERAGE_DISCHARGE_STATUS_ALARM: `$t(filters:AVERAGE_DISCHARGE_STATUS_YELLOW)`,
    DEVICE_USER_EXP_DROP_STATUS_ALARM: `$t(filters:DEVICE_USER_EXPERIENCE_DROP_STATUS_RED)`,
    DEVICE_USER_EXP_LOW_POWER_STATUS_ALARM: `$t(filters:DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_RED)`,
    DEVICE_USER_EXP_REBOOT_STATUS_ALARM: `$t(filters:DEVICE_USER_EXPERIENCE_REBOOT_STATUS_RED)`,
    DEVICE_IDLE_STATUS_ALARM: `$t(filters:DEVICE_UTILISATION_IDLE_STATUS_RED)`,
    DEVICE_OUT_OF_CONTACT_STATUS_ALARM: `$t(filters:DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_RED)`,
    DEVICE_LOCATION_ACCESS_POINT_STATUS_ALARM: `$t(filters:DEVICE_LOCATION_ACCESS_POINT_STATUS_RED)`,
    HEADER: 'Configuración de alarmas',
    HEADER_TOOLTIP: `Las alarmas se activan cuando el porcentaje de dispositivos en estado de alerta dentro de un grupo o ubicación de inicio sea igual o mayor al umbral configurado. <1>Se puede ver las alarmas que alertan actualmente en la página 'Visión general' dentro de la tabla 'Desglose de alertas de su flota'.</1>`,
    MAJOR_ALARM_THRESHOLD: 'Umbral grave de alarma',
    CRITICAL_ALARM_THRESHOLD: 'Umbral crítico de alarma',
    MAJOR_ALARM_THRESHOLD_TOOLTIP: `Las alarmas graves son el primer nivel de alarma. Estas se visualizan como matices amarillos dentro de la tabla 'Desglose de alertas de su flota' en la página 'Visión general'.`,
    CRITICAL_ALARM_THRESHOLD_TOOLTIP: `Las alarmas críticas son las alarmas más severas. Estas se visualizan como matices rojos dentro de la tabla 'Desglose de alertas de su flota' en la página 'Visión general'.`,
    ALERT_TYPE: 'Tipo de alerta',
    ALARM_ACTIVE: 'Alarma activa',
    CRITICAL_THRESHOLD_VALIDATION_ERROR: 'El umbral crítico de alarma debe estar entre 2 y {{ max }} y ser mayor al umbral grave de alarma',
    MAJOR_THRESHOLD_VALIDATION_ERROR: `El umbral grave de alarma debe estar entre 1 y {{ max }} y ser inferior al umbral crítico de alarma`
  },

  userManagement: {
    USER_MANAGEMENT: "Administración de usuarios",
    USER: "usuario",
    USER_other: "usuarios",
    USERS_LIST_TITLE: "Usuarios",
    EMAIL_ADDRESS: "Dirección de correo electrónico",
    ROLE: "Papel",
    CUSTOM_VIEW: "Vista personalizada",
    CUSTOM_VIEW_LIST_TOOLTIP: "Se puede limitar el aceso a los dispositivos de un utilizador por selecionnar la ubicación de inicio, el grupo o la zona a los que quiere que puedan acceder en su edicíon o creación.",
    EDIT_CUSTOM_VIEW_TOOLTIP: "Se puede limitar el aceso a los dispositivos de este utilizador puede acceder por selecionnar la ubicación de inicio, el grupo o la zona a los que quiere que puedan acceder.",
    CUSTOM_VIEW_LIST_ZONE: "Zona: {{ zone }}",
    CUSTOM_VIEW_LIST_GROUPS_HOME_LOCATIONS: "Ubicación de inicio: <1>{{ homeLocation }}</1>, Grupo: <3>{{ group }}</3>",
    ALL_HOME_LOCATIONS: "Todas las ubicaciones de inicio",
    ALL_GROUPS: "Todos los grupos",
    PRODUCT_ACCESS: "Acceso a los productos",
    WORLD_ACCESS: "Acceso a los mundos",
    VIEWER: "Observador",
    EDITOR: "Editor",
    MANAGER: "Director",
    ADMIN: "Administrador",
    ALL_DEVICES: "$t(filters:ALL_DEVICES)",
    ALL_WORLDS: "Todos los mundos",
    EDIT_USER: "Editar el usuario",
    EDIT_USER_DESCRIPTION: "Editar los datos y permisos de este usuario",
    ADD_USER: "Nuevo Usuario",
    ADD_USER_DESCRIPTION: "Agregar un nuevo usuario y configurar sus permisos.",
    EDIT_DENIED_HEADER: "No se puede editar el usuario seleccionado",
    DELETE_DENIED_HEADER: "No se puede borrar el usuario seleccionado",
    DELETE_DENIED_HEADER_other: "No se puede borrar los usuarios seleccionados",
    EDIT_DENIED_SUBHEADER: "No es posible editar su propia cuenta.",
    DELETE_DENIED_SUBHEADER: "No es posible borrar su propia cuenta.",
    RETURN_TO_LIST: "Volver a la lista de usuarios",
    TOOLTIP_VIEWER_TEXT: "El usuario solo puede ver todos los activos inmobiliarios.",
    TOOLTIP_EDITOR_TEXT: "El usuario puede ver todos los activos de la propiedad, editar la membresía del grupo, la ubicación de la casa y la etiqueta de propiedad de un dispositivo, eliminar un dispositivo de la propiedad y quitar una batería de la propiedad.",
    TOOLTIP_MANAGER_TEXT: "El usuario tiene las mismas capacidades que el rol de Editor y también puede configurar ajustes de alarmas y alertas mundiales y administrar la tabla de ubicación de inicio.",
    TOOLTIP_ADMIN_TEXT: "El usuario tiene las mismas capacidades que el rol de Administrador y también puede crear, editar y eliminar cuentas de usuario.",
    TOOLTIP_WORLD_ACCESS: "Se puede autorizar a los usuarios que no son administradores a acceder a más de un mundo, si necesario.  Los administradores siempre pueden aceder a todos los mundos.",
    EMAIL_EXISTS_ERROR: "Ya existe un usuario con esta dirección de correo electrónico.",
    INVALID_EMAIL_ERROR: "Por favor ingrese una dirección de correo electrónico válida.",
    USER_NOT_FOUND: "Este usuario no existe",
  },

  filters: {
    SELECT: 'Seleccionar',
    SELECT_HOME_LOCATION: '$t(filters:SELECT) ubicación de inicio',
    get SELECT_GROUP() { return `${this.SELECT} l'${esTranslations.translation.GROUP.toLowerCase()}`; },
    get SELECT_ZONE() { return `${this.SELECT} la ${esTranslations.zones.ZONE.toLowerCase()}`; },
    ALERT_TYPES_FILTER: `Tipos de alerta`,
    ALERT_TYPES_FILTER_other: `$t(filters:ALERT_TYPES_FILTER)`,
    ALERT_LEVEL_COL: `Nivel de alerta`,
    AVERAGE_DISCHARGE_STATUS_GROUP: `Descarga promedio`,
    DEVICE_LOCATION_ACCESS_POINT_STATUS_GROUP: `Red`,
    DEVICE_LOCATION_DISTANCE_STATUS_GROUP: `Ubicación (distancia)`,
    DEVICE_LOCATION_TIME_STATUS_GROUP: `Ubicación (tiempo)`,
    DEVICE_TIME_ERROR_STATUS_GROUP: `$t(editEvents:TIME_ERROR)`,
    DEVICE_USER_EXPERIENCE_DROP_STATUS_GROUP: `$t(performance:DROPS)`,
    DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_GROUP: `$t(editEvents:DEVICE_LOW_POWER)`,
    DEVICE_USER_EXPERIENCE_REBOOT_STATUS_GROUP: `Reinicios`,
    DEVICE_UTILISATION_IDLE_STATUS_GROUP: `$t(translation:IDLE)`,
    DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_GROUP: `Fuera de contacto`,
    AVERAGE_DISCHARGE_STATUS_YELLOW: `Promedio elevado de descarga por hora`,
    AVERAGE_DISCHARGE_STATUS_GREEN: `Sin alerta de promedio de descarga por hora`,
    DEVICE_LOCATION_ACCESS_POINT_STATUS_RED: `En una red desconocida`,
    DEVICE_LOCATION_ACCESS_POINT_STATUS_YELLOW: `En una red conocida que no pertenece a la ubicación de inicio`,
    DEVICE_LOCATION_ACCESS_POINT_STATUS_GREEN: `Sin alerta de red`,
    DEVICE_LOCATION_DISTANCE_STATUS_RED: `Lejos de la ubicación de inicio`,
    DEVICE_LOCATION_DISTANCE_STATUS_YELLOW: `$t(filters:DEVICE_LOCATION_DISTANCE_STATUS_RED)`,
    DEVICE_LOCATION_DISTANCE_STATUS_GREEN: `Sin alerta de ubicación (distancia)`,
    DEVICE_LOCATION_TIME_STATUS_RED: `Lejos de la ubicación de inicio por un tiempo`,
    DEVICE_LOCATION_TIME_STATUS_YELLOW: `$t(filters:DEVICE_LOCATION_TIME_STATUS_RED)`,
    DEVICE_LOCATION_TIME_STATUS_GREEN: `Sin alerta de ubicación (tiempo)`,
    DEVICE_TIME_ERROR_STATUS_RED: `Actualmente tiene un error de hora`,
    DEVICE_TIME_ERROR_STATUS_GREEN: `Sin alerta de error de hora`,
    DEVICE_USER_EXPERIENCE_DROP_STATUS_RED: `Número elevado de caídas`,
    DEVICE_USER_EXPERIENCE_DROP_STATUS_YELLOW: `$t(filters:DEVICE_USER_EXPERIENCE_DROP_STATUS_RED)`,
    DEVICE_USER_EXPERIENCE_DROP_STATUS_GREEN: `Sin alerta de caídas`,
    DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_RED: `Número elevado de eventos de baja potencia`,
    DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_YELLOW: `$t(filters:DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_RED)`,
    DEVICE_USER_EXPERIENCE_LOW_POWER_STATUS_GREEN: `Sin alertas de baja potencia`,
    DEVICE_USER_EXPERIENCE_REBOOT_STATUS_RED: `Número elevado de reinicios`,
    DEVICE_USER_EXPERIENCE_REBOOT_STATUS_YELLOW: `$t(filters:DEVICE_USER_EXPERIENCE_REBOOT_STATUS_RED)`,
    DEVICE_USER_EXPERIENCE_REBOOT_STATUS_GREEN: `No hay alerta de reinicios`,
    DEVICE_UTILISATION_IDLE_STATUS_RED: `Inactivo por un tiempo`,
    DEVICE_UTILISATION_IDLE_STATUS_YELLOW: `$t(filters:DEVICE_UTILISATION_IDLE_STATUS_RED)`,
    DEVICE_UTILISATION_IDLE_STATUS_GREEN: `Sin alerta de inactividad`,
    DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_RED: `Fuera de contacto por un tiempo`,
    DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_YELLOW: `$t(filters:DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_RED)`,
    DEVICE_UTILISATION_OUT_OF_CONTACT_STATUS_GREEN: `Sin alerta de fuera de contacto`,
    ALL_DEVICES: `Todos los dispositivos`,
    FILTER_BY: `Filtrar por:`,
    TIME_PERIOD: `Período de tiempo:`,
    SELECT_TIME_PERIOD: `Seleccionar el período de tiempo`,
  },

  homeLocations: {
    HOME_LOCATION_MANAGEMENT: "Administración de $t(translation:HOME_LOCATION)",
    UPDATE_TITLE: "Editar ubicación de inicio",
    CREATE_TITLE: "Nueva ubicación de inicio",
    DELETE_TITLE: "Borrar ubicación de inicio",
    DELETE_TITLE_other: "Borrar ubicaciones de inicio",
    UPDATE_TITLE_TOOLTIP: "Edite los detalles de la ubicación de inicio. Los cambios en la latitud, la longitud y el radio pueden afectar las alertas de dispositivos basados ​​en la ubicación actual.",
    CREATE_TITLE_TOOLTIP: "Cree una ubicación geográfica de inicio para sus dispositivos que se pueda utilizar al configurar alertas de dispositivos basadas en la ubicación.",
    DETAILS_TITLE: "Detalles",
    THRESHOLDS_TOOLTIP: "Alerta generada cuando la distancia del dispositivo desde su ubicación de inicio iguala o excede el umbral.",
    THRESHOLDS_TITLE: "Umbrales de alerta de distancia",
    GLOBAL_THRESHOLDS: "Utilice umbrales globales",
    CUSTOM_THRESHOLDS: "Umbrales personalizados",
    LATITUDE: "Latitud",
    LONGITUDE: "Longitud",
    ADDRESS_LINE: "Línea de dirección {{number}}",
    LOCALITY: "Localidad",
    POSTCODE: "ZIP / código postal",
    COUNTRY: "País",
    RADIUS: "Radio",
    RADIUS_WITH_UNIT: `Radio ($t(distance:SHORT_{{context}}_other))`,
    WARNING_THRESHOLD: "Umbral de advertencia",
    PROBLEM_THRESHOLD: "Umbral de problema",
    WARNING_THRESHOLD_WITH_UNIT: `Umbral de advertencia ($t(distance:LONG_{{context}}_other))`,
    PROBLEM_THRESHOLD_WITH_UNIT: `Umbral de problema ($t(distance:LONG_{{context}}_other))`,
    GLOBAL: "Global",
    LATITUDE_VALIDATION_ERROR_RANGE: "La latitud debe estar entre -90 y 90",
    LONGITUDE_VALIDATION_ERROR_RANGE: "La longitud debe estar entre -180 y 180",
    RADIUS_VALIDATION_ERROR_RANGE: "El radio debe ser mayor que 0",
    WARNING_THRESHOLD_VALIDATION_ERROR_RANGE: "El umbral de advertencia debe ser mayor que 0",
    WARNING_THRESHOLD_VALIDATION_ERROR_RELATIVE: "El umbral de advertencia debe ser menor que el umbral del problema",
    WARNING_THRESHOLD_VALIDATION_ERROR_MAX: "El umbral de advertencia debe ser menor que o igual a {{ max }}",
    PROBLEM_THRESHOLD_VALIDATION_ERROR_RANGE: "El umbral de problema debe ser mayor que 0",
    PROBLEM_THRESHOLD_VALIDATION_ERROR_RELATIVE: "El umbral de problema debe ser mayor que el umbral de advertencia",
    PROBLEM_THRESHOLD_VALIDATION_ERROR_MAX: "El umbral de problema debe ser menor que o igual a {{ max }}",
    ERROR_ALREADY_EXISTS: "Ya existe una ubicación de inicio con ese nombre."
  },

  forms: {
    REQUIRED_FIELDS: `Campos requeridos`
  },

  bulkActions: {
    BULK_ACTIONS: "Acciones masivas",
    HOME_LOCATION_CHANGE: "Cambio de ubicación de inicio",
    SELECT_HOME_LOCATION: "Seleccionar ubicación de inicio",
    MOVE: "Desplazar",
    MOVE_DEVICES_TEXT: "Desplazar todos los dispositivos en",
    MOVE_STATUS_TEXT: "Actualización de las ubicaciones de inicio en curso. Espere por favor.",
    MOVE_CONFIRMATION_TEXT: "La ubicación de inicio del <1> {{count}} dispositivo </1> en '{{from}}' se cambiará a '{{to}}'.",
    MOVE_CONFIRMATION_TEXT_other: "La ubicación de inicio de todos los <1> {{count}} dispositivos </1> en '{{from}}' se cambiará a '{{to}}'.",
    MOVE_VALIDATION_ERR_TEXT: "Seleccione una ubicación de inicio diferente.",
    MOVE_SUCCESS_TEXT: "Se desplazaron los dispositivos a '{{location}}' con éxito.",
    MOVE_ERROR_TEXT: "Ocurrió un error, es posible que algunos dispositivos no se hayan desplazado. Inténtelo de nuevo."
  },

  zones: {
    MANAGE_ZONES: "Administrar zonas",
    ZONE: "Zona",
    ZONES: "Zonas",
    ZONE_other: "Zonas",
    AND_GROUPS: `AND $t(translation:GROUPS)`,
    AND_HOMELOCATIONS: 'AND $t(translation:HOME_LOCATION_other)',
    NEW_ZONE: "Nueva Zona",
    EDIT_ZONE: "Editar zona",
    ZONE_NAME: "Nombre de zona",
    GROUPS1_TAB_HEADER: "Seleccione grupo(s) de dispositivos para agregar a la zona.",
    GROUPS2_TAB_HEADER: "Agregue grupos, a la zona, a los que TAMBIÉN deben pertenecer los dispositivos.",
    HOMELOCATIONS_TAB_HEADER: "Seleccione la(s) ubicación(es) de casa para agregar a la zona.",
    ZONE_TAB_DESCRIPTION: "Asigne al menos un grupo o una ubicación de casa para crear esta zona.",
    ZONE_OPTIONS_TEXT_GROUPS1: "Cualquier dispositivo que esté en el grupo(s):",
    ZONE_OPTIONS_TEXT_GROUPS2: "Y eso también está en el (los) grupo (s):",
    ZONE_OPTIONS_TEXT_HOMELOCATIONS: "Y eso también está en la(s) ubicación(es) de origen:",
    ZONE_OPTIONS_TEXT_HOMELOCATIONS_without_groups: 'Cualquier dispositivo que esté en la(s) ubicación(es) de casa:',
    ZONE_SUMMARY: "Resumen de la zona",
    NAME_VALIDATION_ERROR: "Nombre inválido, por favor use solo alfanumérico sin espacios.",
    NAME_EXISTS_ERROR: "La zona con ese nombre ya existe. Ingrese un nombre diferente.",
    SAVE_FAILED_ERROR: "La acción falló para esta zona, intente nuevamente.",
    OPTION_SELECTION_ERROR: "Las opciones seleccionadas no deben exceder más de 20.",
    EDIT_ZONE_NAVIGATION_TEXT: "Seleccione una Zona de la lista para editar",
    SUBHEADER_SINGLE: "‘{{ zone }}’ será borrado.",
    SUBHEADER: "{{ count }} zona será borrada.",
    SUBHEADER_other: "{{ count }} zonas serán borradas.",
    MESSAGE: "Ya no podrá asignar grupos o ubicaciones de inicio a esta zona.",
    MESSAGE_other: "Ya no podrá asignar grupos o ubicaciones de inicio a estas zonas.",
    CONFIRM_DELETE: "Sí, borrar esta zona",
    CONFIRM_DELETE_other: "Sí, borrar estas zonas",
    FAILURE_HEADER: "No se puede borrar la zona",
    FAILURE_HEADER_other: "No se puede borrar las zonas",
    FAILURE_HEADER_USERS: "La siguiente zona no se puede eliminar porque tiene usuarios asignados:",
    FAILURE_HEADER_USERS_other: "Las siguientes zonas no se pueden eliminar porque tienen usuarios asignados:",
    FAILURE_FOOTER_USERS: "Puede cambiar las zonas de estos usuarios individualmente.",
    ZONE_NOT_FOUND: "La zona no existe"
  },

  deviceNetwork: {
    NETWORK: 'Red',
    SIGNAL_TAB_TITLE: 'Señal',
    '2G': '2G',
    '3G': '3G',
    '4G': '4G',
    '5G': '5G',
    SIGNAL_LEVEL: 'Nivel de señal',
    CHANGE_SIGNAL_LEVEL: 'Cambiar el nivel de señal',
    SIGNAL: 'Señal',
    'LAT/LONG': 'Lat/Long',
    OPERATOR: 'Operador',
    VERY_POOR: 'Muy Pobre',
    POOR: 'Bajo',
    MODERATE: 'Moderado',
    FAIR: 'Bastante fuerte',
    GOOD: 'Fuerte',
    GREAT: 'Muy fuerte',
    BAR_COUNT: '{{ count }} barra',
    BAR_COUNT_other: '{{ count }} barras',
    NO_SERVICE: 'Sin servicio',
    EDIT_EVENTS_MODAL_HEADER: `Edite los tipos de eventos que se muestran en los gráficos de nivel de señal.`
  },

  deviceCoverage: {
    DEVICE_COVERAGE_TAB_TITLE: "El Reportaje"
  },

  aboutPopup: {
    CONTENT_HEADER: 'Elemez de B2M Solutions',
    CONTENT_HEADER_PANASONIC: 'Panasonic Smart Essentials funciona con Elemez de B2M Solutions',
    COPYRIGHT: 'Derechos de autor \u00A9 2015 - {{ currentYear }} Biz2mobile Ltd. Reservados todos los derechos.',
    HEADER: "Acerca de Elemez",
    HEADER_PANASONIC: "Acerca de Panasonic Smart Essentials",
    LICENSE_HEADER: "Software de código abierto"
  }

};
